<template>
    <b-card no-body  @click="goToEvent" style="cursor: pointer">
        <div class="card-header-img">
            <img :src="imgSrc" class="card-img card-img-top "  @error="handleImgNotFound" v-bind:class="{ 'card-img-missing': imgMissClass }" :alt="event.title" />
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-3 mb-0">
                    <h4 class="text-secondary text-center month">{{event.shortdate[0].split("_")[1]}}</h4>
                    <h4 class="text-secondary text-center date">{{event.shortdate[0].split("_")[0]}}</h4>
                </div>
                <div class="col-9 mb-0">
                    <h5 class="evt-title" :style="event.custom_text_colour ? {color: event.custom_text_colour} : {color: '#ff9500'}">​{{event.title}}</h5>
                    <h6>{{event.datetimefrom}}</h6>
                    <h6 v-if="event.datetimefrom != event.datetimeto">{{event.datetimeto}}</h6>
                    <h6>{{event.location}}</h6>
                    <h6>{{event.lowest_price}}</h6>
                </div>
            </div>
            <div class="socials">
                <socialMediaBtn :eventTitle="event.title" :bookingUrl="event.booking_url"></socialMediaBtn>
            </div>
        </div>
    </b-card>
</template>

<script>
    import socialMediaBtn from "./social-media-share";

    export default {
        data() {
            return {
                /*imgSrc: "<https://via.placeholder.com/400x200?text=Your+Event+Image+Here>"*/
                imgSrc: "/assets/placeholder.png"
            }
        },
        props: ['event'],
        components: {
          socialMediaBtn: socialMediaBtn
        },
        methods: {
            goToEvent(){
                var eventUrl = ""
                if (this.event.custom_url) {
                    eventUrl = '/' + this.event.custom_url;
                } else {
                    eventUrl = "/" + 'event/' + this.event.id + '/' + this.event.title.toLowerCase().replace(/[\\!&|?$%@':;.<>/"=+\s]/g, '_');
                }
                window.location = eventUrl
            },
            handleImgNotFound(){
                this.imgSrc = "/assets/placeholder.png";
            }
        },
        mounted() {
            if(this.event.image_url) {
                this.imgSrc = `${this.event.image_url}?${Math.random()}`;
            }
        },
        watch: {
            event(newVal, oldVal) {
                this.imgSrc = newVal.image_url ? `${newVal.image_url}?${Math.random()}` : "/assets/placeholder.png";
            }
        },
        computed: {
            imgMissClass(){
                return this.imgSrc === "/assets/placeholder.png";
            }
        }
    }
</script>
