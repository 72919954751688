<template>

  <div>
    <events-filter @filterEvents="filterEvents"></events-filter>

    <div class="container">
      <div class="events">
        <h2>UPCOMING EVENTS NEAR YOU</h2>

        <div>
          <event-deck v-if="pageEvents.length > 0" :events="pageEvents.length >= 6 ? pageEvents.slice(0, 6) : pageEvents.slice(0)"></event-deck>
          <event-deck v-if="pageEvents.length > 6" :events="pageEvents.slice(6)"></event-deck>
          <h3 v-if="events.length == 0">No Events found.</h3>
        </div>

        <b-pagination
                v-if="events.length > 0"
                size="md"
                :total-rows="events_count"
                v-model="currentPage"
                :per-page="6"
        >
        </b-pagination>

      </div>
    </div>
  </div>

</template>

<script>
import eventDeck from "./events-card-deck";
import eventsFilter from "./events-filter"

export default {
  data() {
    return {
      events: [],
      currentPage: 1,
      filter: {
          title: "",
          date_on: null,
          location: null,
          tag: null,
          type: null,
          cost: 'all'
      },
      events_count: 0
    };
  },

  created() {
    var self = this;
    self.changePage();
  },

  methods: {
    filterEvents(filter) {
      this.filter = filter;
      this.changePage();
    },

    changePage(page = 1) {
      var self = this;

      if (page) {
        self.currentPage = page;
      }

      this.$http
        .get("/public_events", {
          params: {
            title: self.filter.title,
            date_on: self.filter.date_on,
            location: self.filter.location,
            tag: self.filter.tag,
            type: self.filter.type,
            cost: self.filter.cost
          }
        })
        .then(function(response) {
          console.log(response.data);
          self.events = response.data.events;
          self.events_count = response.data.events_count;
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },

  components: {
    eventDeck: eventDeck,
    eventsFilter: eventsFilter
  },

  computed: {
      pageEvents() {
          let start = (this.currentPage - 1) * 6;
          let end = (this.currentPage * 6);
          if(this.currentPage * 6 <= this.events.length) {
              return this.events.slice(start, end )
          } else {
              return this.events.slice(start)
          }
      }
  }
};
</script>

<style>
  .page-link {
        color: #ff9500 !important;
    }

    .page-item  {
        z-index: 1;
        color: #fff;
        border-color: #ff9500 !important;
    }

    .page-item.active .page-link {
        z-index: 1;
        color: #fff !important;
        background-color: #ff9500 !important;
        border-color: #ff9500 !important;
    }
</style>



